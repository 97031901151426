import React from 'react'

export default function Software() {
    return (
        <div className="software">
            <div className="title">
                <h3>01.</h3>
                <h1>software<br />programming</h1>
            </div>
            <div className="stats">
                <div className="stat">
                    <div className="title">
                        <p>Python</p>
                    </div>
                   
                    <div className="progress-bar">
                        <div className="four-five left-bar"></div>
                    </div>
                </div>
                <div className="stat">
                    <div className="title">
                        <p>Java</p>
                    </div>
                    <div className="progress-bar">
                        <div className="four-five left-bar"></div>
                    </div>
                </div>
                <div className="stat">
                    <div className="title">
                        <p>C</p>
                    </div>
                   
                    <div className="progress-bar">
                        <div className="three-five left-bar"></div>
                    </div>
                </div>
                <div className="stat">
                    <div className="title">
                        <p>SQL</p>
                    </div>
                   
                    <div className="progress-bar">
                        <div className="two-five left-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
