import React from 'react'

export default function Options() {
    return (
        <ul className="options">
            <li className="opt-email">
                <a href="mailto: eretana238@gmail.com">
                    Email
                </a>
            </li>
            <li className="opt-linkedin">
                <a href="https://www.linkedin.com/in/esteban-retana-98a6b6174" target="_blank" rel="noopener noreferrer">
                    Linkedin
                </a>
            </li>
            <li className="opt-github">
                <a href="https://github.com/eretana238" target="_blank" rel="noopener noreferrer">
                    Github
                </a>
            </li>
        </ul>
    )
}
