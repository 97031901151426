import React from 'react'

export default function Awards() {
    return (
        <div className="awards">
            <div className="block">
                <div className="block-container">
                    <div className="heading">
                        <h3>03.</h3>
                        <h1>Awards and Honors</h1>
                    </div>
                    <p><i className="year">2019</i> ACM Hack 915 Hackathon finalists <i className="">El Paso, TX</i></p>
                    <p><i className="year">2017</i> VEX World's 3D Game Design 1st Place <i className="">Louisville, KY</i></p>
                </div>
            </div>
        </div>
    )
}
