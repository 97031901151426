import React from 'react'

export default function Web() {
    return (
        <div className="web">
            <div className="title">
                <h3>02.</h3>
                <h1>web<br />programming</h1>
            </div>
            <div className="stats">
                <div className="stat">
                    <div className="title">
                        <p>Javascript</p>
                    </div>
                    <div className="progress-bar">
                        <div className="three-five right-bar"></div>
                    </div>
                </div>
                <div className="stat">
                    <div className="title">
                        <p>mySQL</p>
                    </div>
                    <div className="progress-bar">
                        <div className="two-five right-bar"></div>
                    </div>
                </div>
                <div className="stat">
                    <div className="title">
                        <p>Html/Css</p>
                    </div>
                    <div className="progress-bar">
                        <div className="four-five right-bar"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
