import React, { useEffect, lazy, Suspense} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import './App.css'

import About from './pages/about/About'
import Skills from './pages/skills/Skills'
import Projects from './pages/projects/Projects'
import Contact from './pages/contact/Contact'
import NavBar from './components/navigation/NavBar'

function error() {
  return (
    <div className="error-page">
      <h1>Error-404</h1>
      <p>The page you requested could not be found.</p>
      <p>Maybe the link moved or doesn't exist anymore!</p>
    </div>
  )
}
const preloader = () => {
  return(
    <div className='preloader'>
      <div className='progress-bar'></div>
      <div className='loading-text'>loading</div>
    </div>
  )
}

function App() {
  const Home = lazy(() => import('./pages/home/Home'))

  useEffect(() => {
    // preload anim

  },[])
  return (
    <BrowserRouter>
      <Suspense fallback={preloader}>
        <div className="App">
          <NavBar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/skills" component={Skills} />
            <Route path="/projects" component={Projects} />
            <Route path="/contact" component={Contact} />
            <Route component={error} />
          </Switch>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}  


export default App;
