import React, { useEffect } from 'react'
import Web from './Web'
import Software from './Software'
import Awards from './Awards'
import Footer from '../../components/footer/Footer'

import anime from 'animejs/lib/anime.es'

export default function Skills() {
    useEffect(() => {
        if(document.body.clientWidth < 800){
            let initTL = anime.timeline({
                easing: 'easeInOutQuad',
            })
            initTL
            // background
                .add({
                    targets: 'body',
                    background: '#fff',
                    color: '#041E14',
                    duration: 1000
                },0)
            // anim nav
                .add({
                    targets: '.home-link .logo',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-skills',
                    color: '#31E981'
                },0)
                .add({
                    targets: '.nav-about, .nav-projects',
                    color: '#DFF8EB'
                },0)
                .add({
                    targets: '.nav-contact',
                    color: '#31E981',
                    background: 'rgba(0,0,0,0)'
                },0)
                .add({
                    targets: '.arrow-btn i',
                    color: '#041E14'
                },0)    
        }
        else{
            let initTL = anime.timeline({
                easing: 'easeInOutQuad',
            })
            initTL
            // background
                .add({
                    targets: 'body',
                    background: '#fff',
                    color: '#041E14',
                    duration: 1000,
                },0)
            // anim nav
                .add({
                    targets: '.home-link .logo',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-skills',
                    color: '#31E981'
                },0)
                .add({
                    targets: '.nav-about, .nav-projects',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-contact',
                    color: '#31E981',
                    background: 'rgba(0,0,0,0)'
                },0)
                .add({
                    targets: '.arrow-btn i',
                    color: '#041E14'
                },0)
        }
    })

    return (
        <div className="skills container">
            <div className="header">
                <h2>Skills.</h2>
            </div>
            <Software />
            <Web />
            <Awards />
            <div className='v-line line-left'></div>
            <div className='v-line line-right'></div>
            <Footer left="/about" right="/projects" />
        </div>
    )
}
