import React from 'react'
import ResumeDoc from '../../assets/EstebanRetanaResume.docx'
import ResumePdf from '../../assets/EstebanRetanaResume.pdf'

export default function Description() {
    return (
        <div className="description">
            <h2 className="title">About me.</h2>
            <h3>Hello!</h3>
            <p>I am a software engineer in the making that is currently pursuing a bachelors in computer science at the University of Texas at El Paso. I enjoy creating projects with others, exposing myself to new technologies, attending hackathons and competitions, to learn and apply these newly learnt skills to help others. </p>
            <p>Expected graduation date: Fall 2021</p>
            <div className="link">
                    <a className='btn' href={ResumePdf} target="_blank">View my Resume as PDF</a>
            </div>
            <div className="link">
                    <a className='btn' href={ResumeDoc} target="_blank">Download Resume as .docx</a>
            </div>
            
        </div>
    )
}
