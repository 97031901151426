import React, { useState, useEffect} from 'react'

export default function Cards() {
    const [data, setData] = useState([])
    const [initialized, setInitialized] = useState(false)

    const url = "https://api.github.com"
    const user = "eretana238"

    useEffect(() => {
        if(!initialized){
            fetch(`${url}/users/${user}/repos`)
                .then(res => res.json())
                .then(data => {
                    setData(data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
        setInitialized(true)
    },[])

    const cards = data.map(item => {
        return(
            <li className="card" key={item.id}>
                <div className="name">
                    {item.name}
                </div>
                <div className="description">
                    {item.description}
                </div>
                <div className="link">
                    <a className='btn' href={item.html_url} target='_blank' rel='noopener noreferrer'>CHECKOUT</a> 
                </div>
            </li>
        )
    })

    return (
        <ul className="cards">
            { initialized ? cards : <div className="loader">loading</div> }
        </ul>
    )
}
