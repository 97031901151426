import React, { useEffect } from 'react'
import anime from 'animejs/lib/anime.es'
import Profile from '../../assets/me.jpg'

import Description from './Description'
import Footer from '../../components/footer/Footer'

export default function About() {
    useEffect(() => {
        if(document.body.clientWidth < 800){
            let initTL = anime.timeline({
                easing: 'easeInOutQuad',
            })
            initTL
            // background
                .add({
                    targets: 'body',
                    background: '#fff',
                    color: '#041E14',
                    duration: 1000
                },0)
            // anim nav
                .add({
                    targets: '.home-link .logo',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-about',
                    color: '#31E981'
                },0)
                .add({
                    targets: '.nav-skills, .nav-projects',
                    color: '#DFF8EB'
                },0)
                .add({
                    targets: '.nav-contact',
                    color: '#31E981',
                    background: 'rgba(0,0,0,0)'
                },0)
                .add({
                    targets: '.arrow-btn i',
                    color: '#041E14'
                },0)    
        }
        else{
            let initTL = anime.timeline({
                easing: 'easeInOutQuad',
            })
            initTL
            // background
                .add({
                    targets: 'body',
                    background: '#fff',
                    color: '#041E14',
                    duration: 1000,
                },0)
            // anim nav
                .add({
                    targets: '.home-link .logo',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-about',
                    color: '#31E981'
                },0)
                .add({
                    targets: '.nav-skills, .nav-projects',
                    color: '#041E14'
                },0)
                .add({
                    targets: '.nav-contact',
                    color: '#31E981',
                    background: 'rgba(0,0,0,0)'
                },0)
                .add({
                    targets: '.arrow-btn i',
                    color: '#041E14'
                },0)
        }
    })
    return (
        <div className="about container">
            <div className="header">
                <Description />
                <div className="profile">
                    <img className="pic" src={Profile} alt="self-portrait" />
                    <div className="background"></div>
                </div>
            </div>

            <div className='v-line line-left'></div>
            <div className='v-line line-right'></div>

            <Footer left="/" right="/skills" />
        </div>
    )
}
