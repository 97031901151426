import React from 'react'
import Arrow from '../arrow/Arrow'
import { Link } from 'react-router-dom'

export default function Footer(props) {
    return (
        <footer>
            <div className="arrows">
                <Link className="left" to={props.left}>
                    <Arrow left={true} />
                </Link>
                <Link className="right" to={props.right}>
                    <Arrow left={false} />
                </Link>
            </div>
        </footer>
    )
}
