import React from 'react'

export default function Arrow(props) {

    const direction = props.left === true ? <i className="fas fa-angle-left"></i> : <i className="fas fa-angle-right"></i>
    return (
        <div className="arrow-btn">
            {direction}
        </div>
    )
}
