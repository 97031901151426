import React from 'react'
import Typewriter from 'typewriter-effect'

export default function Type() {
    return (
        <div className="type">
            <Typewriter
                options={{
                    autoStart: true
                }}
                onInit={(typewriter) =>{
                    typewriter.typeString('Say ih thghour')
                        .pauseFor(400)
                        .deleteChars(10)
                    typewriter.typeString('hi throuhg')
                        .pauseFor(700)
                        .deleteChars(2)
                    typewriter.typeString('gh:')
                        .start()
                }}
            />
        </div>
    )
}
