import React, { useEffect } from 'react'
import Type from './Type'
import Options from './Options'
import Footer from './Footer'

import anime from 'animejs/lib/anime.es'

export default function Contact() {
    useEffect(() => {
        let timeline = anime.timeline({
            easing: 'easeInOutQuad',
            duration: 1000
        })
        timeline
        // background
            .add({
                targets: 'body',
                background: 'rgb(13, 19, 17)',
                color: ['#031B11', '#DFF8EB']
            },0)
            .add({
                targets: '.options, .options a',
                color: ['#031B11', '#DFF8EB']
            },0)
        // anim nav
            .add({
                targets: '.home-link a, .main-nav a:not(:last-child)',
                color: '#DFF8EB',
            },0)
            .add({
                targets: '.main-nav a:last-child',
                color: '#031B11',
                background: '#31E981'
            },0)
            .add({
                targets: '.arrow-btn i',
                color: '#fff'
            },0)
            .add({
                targets: '.v-line',
                background: '#606060'
            },0)
    }, [])


    return (
        <div className="contact container">
            <div className="wrapper">
                <div className="info">
                    <Type />
                    <Options />
                </div>
            </div>
            <div className='v-line line-left'></div>
            <div className='v-line line-right'></div>
            <Footer />
        </div>
    )
}
