import React from 'react'
import { Link } from 'react-router-dom'

function ShowHideNav(){
  let nav = document.querySelector(".bg-nav")
  // add attribute display to main-nav or bg-nav
  if(nav.style.display === "none"){
    nav.style.display = "block"
    document.querySelector("nav").style.height = "100vh"
  }
  else{
    nav.style.display = "none"
    document.querySelector("nav").style.height = "40px"
  }
}

function HideOnSelect(){
  if(document.querySelector(".bg-nav").style.display === "block"){
    document.querySelector(".bg-nav").style.display = "none"
    document.querySelector("nav").style.height = "40px"
  }
}

function NavBar(){
  return(
    <nav>
      <div className="home-link">
        <Link to="/" className="logo">eretana.</Link>
      </div>
      <div className="burger" onClick={ShowHideNav}>
          <div className="burger-line"></div>
          <div className="burger-line"></div>
          <div className="burger-line"></div>
        </div>
      <div className="bg-nav">
        <div className="main-nav" onClick={HideOnSelect}>
          <Link to="/about" className="nav-about">about.</Link>
          <Link to="/skills" className="nav-skills">skills.</Link>
          <Link to="/projects" className="nav-projects">projects.</Link>
          <Link to="/contact" className="nav-contact">contact.</Link>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
