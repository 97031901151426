import React from 'react'
import Arrow from '../../components/arrow/Arrow'

import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer>
            <div className="arrows">
                <Link to="/projects">
                    <Arrow left={true} />
                </Link>
            </div>
        </footer>
    )
}
